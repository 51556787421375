.form-control {
  border: none;
  min-height: 60px;
  color: #333;
  &:focus {
    color: #333;
    box-shadow: 0 0 0 0.25rem rgba(0, 0, 0, 0.08);
  }
}
.form-control::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #c4c4c4;
}
.form-control::-moz-placeholder {
  /* Firefox 19+ */
  color: #c4c4c4;
  opacity: 1;
}
.form-control:-ms-input-placeholder {
  /* IE 10+ */
  color: #c4c4c4;
}
.form-control:-moz-placeholder {
  /* Firefox 18- */
  color: #c4c4c4;
  opacity: 1;
}
label {
  line-height: 1.1;
}
.form-select {
  color: #212529;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  appearance: none;
}
.btn-primary-small {
  background-color: $primary;
  border-color: $primary;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  font-family: Arial, Helvetica, sans-serif;
  letter-spacing: 0.025em;
  border-radius: 25px;
  padding: 0.1rem 1rem;
}

.btn {
  -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
  border-radius: 40px;
}
.btn-primary {
  color: $white;
  font-size: 22px;
  font-weight: 400;
  font-family: Arial, Helvetica, sans-serif;
  letter-spacing: 0.025em;
  padding: 0.563rem 1.905rem;
}
.btn-secondary {
  color: #fff;
  font-size: 20px;
  padding: 0.563rem 1.905rem;
  background-color: #1f2226;
  border-color: #1f2226;
  letter-spacing: 0.025em;
}
.btn-secondary:hover {
  color: #fff;
  background-color: rgb(48, 48, 48);
  border-color: #1f2226;
}
.btn-2 {
  padding: 0.563rem 3.81rem;
}
.title-block {
  align-items: center;
  .title-img {
    width: 100%;
    max-width: 56px;
    margin: 0 0.5rem 0 0;
  }
  img {
    width: 100%;
  }
}
.page-title {
  color: $text-drk-grey;
  font-size: 38px;
  font-weight: 300;
  line-height: 1;
  span {
    color: inherit;
    font-weight: 400;
    line-height: inherit;
  }
}
.sub-text {
  color: $text-drk-grey;
  font-size: 22px;
  font-weight: 400;
  line-height: 0.97;
}
.form-check-input:checked {
  background-color: #707070;
  border-color: #707070;
  border: 1px solid rgba(0, 0, 0, 0.25);
}
.form-check-input:focus {
  border-color: #d195b4;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(0, 0, 0, 0.08);
  border: 1px solid rgba(0, 0, 0, 0.25);
}
.form-check-input,
.form-check-label,
.form-check {
  cursor: pointer;
}

.btn-bdr {
  border: solid #28a7b3 3px;
  border-radius: 8px;
  box-shadow: none;
  color: #28a7b3;
  font-weight: 500;
  &:hover {
    background-color: $primary;
    color: #fff;
  }
}

.form-label {
  margin-bottom: 7px;
}
/*   welcome css   */

.welcome-main {
  .page-title {
    color: $text-drk-grey;
    font-size: 44px;
    font-weight: 300;
    line-height: 1;
  }
  .about-title {
    color: $text-drk-grey;
    font-size: 30px;
    font-weight: 300;
    line-height: 1;
    font-weight: 600;
  }
  .wl-sub-text {
    color: $primary;
    font-size: 28px;
    font-weight: 300;
  }
  .btn-2 {
    padding: 0.563rem 2rem;
    font-size: 22px;
  }
  .bottom-row {
    .text-r {
      color: $text-drk-grey;
      font-size: 28px;
      font-weight: 300;
      line-height: 1.1;
      span {
        color: $primary;
        font-family: inherit;
        line-height: inherit;
        font-weight: 700;
      }
    }
  }
  .disclaimer {
    color: #383f48;
    font-size: 14px;
    line-height: 1.1;
  }
}

/*   download main   */

.download-main {
  height: 100%;
  .page-title {
    color: $text-drk-grey;
    font-size: 44px;
    font-weight: 300;
    line-height: 1;
  }
  .dl-sub-text {
    color: $text-drk-grey;
    font-size: 44px;
    font-weight: 400;
  }
  .mid-text {
    color: $text-drk-grey;
    font-size: 26px;
    font-weight: 700;
    line-height: 1.4;
  }
  .btn-2 {
    padding: 0.563rem 2rem;
    font-size: 22px;
  }
  .dl-bottom-links {
    color: $primary;
    font-size: 14px;
    font-weight: 400;
  }
}

/*   about css   */
.about-img{
  max-width: 350px;
  width: 100%;
}

@include media-breakpoint-down(xl) {
  .welcome-logo {
    img {
      width: 100%;
      max-width: 380px;
    }
  }
  .welcome-main {
    .page-title {
      font-size: calc(1.75rem + 1vw);
    }
    .wl-sub-text {
      color: #46bbc5;
      font-size: 28px;
      font-size: calc(1.25rem + 0.8vw);
    }
    .btn-2 {
      padding: 0.47rem 1.5rem 0.4rem;
      font-size: 20px;
    }
  }

  .btn-primary {
    font-size: 20px;
    padding: 0.5rem 1.75rem;
  }

  .btn-2 {
    padding: 0.47rem 1.5rem 0.4rem;
    font-size: 20px;
  }
  .page-title {
    font-size: calc(1.5rem + 1vw);
  }
  .sub-text {
    font-size: calc(0.75rem + 0.5vw);
  }
}
