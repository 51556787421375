.questions-page {
  .title-block {
    margin-bottom: 1.5vw;
  }
  align-items: center;
  .questions-item-block {
    .question-item {
      & + .question-item {
        margin-top: 2vh;
      }
      padding: 0;
      background-color: $white;
      border-radius: 9px;
      &.que-error{
        // background-color: $red-200;
        box-shadow: 0 .15rem 1rem rgba(255, 0, 0, 0.5);
      }
      .question-content {
        padding: 1.15rem;
      }
      .question-footer {
        color: #787878;
        border-top: solid 1px #ccc;
        padding: 1.15rem;
        .footer-img {
          max-width: 50px;
          img {
            width: 100%;
          }
        }
        div p {
          margin: 0;
          line-height: 1.1;
        }
      }
      .question-title {
        font-size: 24px;
        span p {
          margin: 0;
          display: inherit;
        }
      }
    }
  }
  .que-chart-block {
    .chart-item {
      svg {
        width: 84px;
        height: 84px;
        transform: rotate(-90deg);
      }
    }
    .chart-block {
      .chart-item .num {
        font-size: 22px;
      }
    }
  }
}


@include media-breakpoint-down(xxl) {
  .questions-page {
    .questions-item-block {
      .question-item {
        .question-title {
          // font-size: 25px;
          font-size: calc(1rem + 0.5vw);
        }
        .question-content{
          padding: 1rem 1rem 0.35rem;
        }
        .question-footer{
          padding: 0.8rem 1rem;
        }
      }
    }
    .form-check{
      width: 30%;
      margin-bottom: 5px;
    }
  }
}
@include media-breakpoint-down(xl) {
  .questions-page {
    .questions-item-block {
      .question-item {
        .question-title {
          // font-size: 25px;
          font-size: calc(1rem + 0.5vw);
        }
      }
    }
    .que-chart-block {
      .chart-block{
        strong{
          min-height: 38px;
          display: block;
        }
        .chart-item {
          svg {
            width: 70px;
            height: 70px;
          }
          .num {
            font-size: 17px;
        }
        }
      }
    }
  }
}
@include media-breakpoint-down(md) {
  
}

@include media-breakpoint-down(sm) {
  .questions-page {
    .form-check {
      width: 45%;
    }
  }
}

@media (max-width: 640px) {
  .questions-page {
    .form-check {
      width: 90%;
    }
  }
}
