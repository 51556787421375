.container {
  max-width: 1366px;
  margin: 0 auto;
}

.sign-in .sign-in-box {
  margin-top: 3rem;
  padding-bottom: 60px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
}

.text-center {
  text-align: center !important;
}

.btn {
  -webkit-box-shadow: 3px 3px 5px #00000029;
  box-shadow: 3px 3px 5px #00000029;
  border-radius: 7px;
}

.form-input-sec label {
  font-size: 22px !important;
}

.form-input-sec > input {
  border: 1px solid #ced4da !important;
  min-height: 38px !important;
}
.radio-set {
  height: 22px;
  width: 22px;
}

@media only screen and (max-width: 540px)  {
  .m-bottom {
    margin-bottom: 16px;
  }
}
