@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;700&display=swap");

@import "variables";
@import "~bootstrap/scss/bootstrap.scss";
@import "loader";
@import "welcome";
@import "user-form";
@import "login";
@import "questions";
@import "invite-form";
@import "score-details";
@import "congratulations";

body {
  margin: 0;
  // font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  //   'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  //   sans-serif;
  font-family: "Nunito", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
