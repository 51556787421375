.invite-form-block {
  justify-content: space-between;
  flex-wrap: wrap;
  .invite-form-item {
    width: 47%;
    margin-top: 15px;
  }
  .close-btn {
    cursor: pointer;
    margin-bottom: 10px;
    text-align: right;
    img {
      max-width: 28px;
    }
  }
}

.invite-form {
  height: 100%;
}

@include media-breakpoint-down(md) {
  .invite-form-block {
    .invite-form-item {
      width: 100%;
      margin-top: 15px;
  }
  }
}
