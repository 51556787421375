.featured-links {
  .featured-link-item {
    background-color: rgba($color: #fff, $alpha: 0.45);
    border-radius: 7px;
    border: solid 1px rgba($color: $primary, $alpha: 0.5);
    padding: 10px;
    line-height: 1.2;
    a {
      color: $primary;
      text-decoration: underline;
      line-height: 1.2;
      display: inline-block;
      min-height: 40px;
    }
    p {
      color: $primary;
      font-weight: 700;
    }
  }
}
.score-details-main {
  .congr-top-image {
    max-width: 100px;
    img {
      width: 100%;
    }
  }
  .chart-block {
    svg {
      width: 240px !important;
      height: 240px !important;
    }
  }
  .featured-link-item {
    max-width: 320px;
    & + .featured-link-item {
      margin-left: 20px;
    }
    a {
      text-decoration: none;
    }
    .fl-title {
      color: #0109ff;
      text-decoration: underline;
      min-height: 40px;
    }
    figure {
      margin: 0;
    }
  }
}

.topright-img {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 0;
  max-width: 340px;
  width: 17vw;
}
@include media-breakpoint-down(lg) {
  .score-details-main {
    .chart-block svg {
      width: 200px !important;
      height: 200px !important;
    }
  }
}
@include media-breakpoint-down(md) {
  .score-details-main {
    .chart-block svg {
      width: 180px !important;
      height: 180px !important; 
  }
    .featured-link-item {
      + {
        .featured-link-item {
          margin-left: 0;
        }
      }
    }
  }
}